/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

// Assets
import ArrowDown from '../../../images/selectProjectCountries/arrow-down.svg';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Select({
  inputId,
  placeholder,
  options,
  name,
  label,
  fieldErrorMessage,
  wasSubmitted,
  height,
  hasCustomFunction,
  addSelectedObject,
  clearInput,
}) {
  /* ******************************** HOOKS ******************************* */
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [touched, setTouched] = useState(false);
  const [currOptions, setCurrOptions] = useState(options);

  if (hasCustomFunction && selectedOption) {
    const object = { [name]: selectedOption };
    addSelectedObject(object);
  }

  useEffect(() => {
    if (clearInput) {
      setSelectedOption();
      setTouched(false);
    }
  }, [clearInput]);

  /* ******************************** CALLBACKS ******************************* */
  function filterOptions(value) {
    setCurrOptions(
      options.filter((o) => {
        return o?.label.toLowerCase().includes(value.toLowerCase());
      }),
    );
  }
  function getFieldError() {
    if (!selectedOption) {
      return fieldErrorMessage?.empty;
    }
    return null;
  }

  const errorMessage = getFieldError();
  const displayErrorMessage = (wasSubmitted || touched) && errorMessage;

  function toggleOptions() {
    setIsOptionsOpen(!isOptionsOpen);
  }

  const handleKeyDown = (value) => (e) => {
    if (e.key === ' ' || e.key === 'SpaceBar' || e.key === 'Enter') {
      e.preventDefault();
      setSelectedOption(value);
      setIsOptionsOpen(false);
    }
  };

  function handleBlur(e) {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsOptionsOpen(false);
      setTouched(true);
      setCurrOptions(options);
    }
  }

  useEffect(() => {
    setCurrOptions(options);
  }, [options]);

  /* ******************************** VARIABLES ******************************* */
  let buttonClassName = '';
  if (!selectedOption) {
    buttonClassName += 'default';
  }
  if (isOptionsOpen) {
    buttonClassName += ' expanded';
  }

  return (
    <div className="custom-select" onBlur={handleBlur}>
      <label htmlFor={inputId}>{label}</label>
      <div className="native-select">
        <select name={name} id={inputId} value={selectedOption}>
          <option value={selectedOption}>{placeholder}</option>
          {options?.map((option, index) => (
            <option
              key={option?.value}
              value={options[index]?.value}
              selected={option?.value === selectedOption}
            >
              {option?.label}
            </option>
          ))}
        </select>
      </div>
      <div className="wrapper">
        <div
          className="container"
          style={height !== undefined ? { height: `${height}px` } : {}}
          onBlur={handleBlur}
        >
          {isOptionsOpen ? (
            <input
              type="text"
              name=""
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              onChange={(e) => filterOptions(e.target.value)}
            />
          ) : (
            <button
              type="button"
              aria-haspopup="listbox"
              aria-expanded={isOptionsOpen}
              aria-labelledby={inputId}
              className={buttonClassName}
              onClick={toggleOptions}
            >
              {!selectedOption
                ? placeholder
                : options.find((op) => op?.value === selectedOption)?.label}
              <span className={`arrow ${isOptionsOpen ? 'rotate' : ''}`}>
                <img src={ArrowDown} alt="" aria-hidden="true" />
              </span>
            </button>
          )}
          <div className={`options-container ${isOptionsOpen ? 'show' : ''}`}>
            <ul className="options" tabIndex={-1}>
              {currOptions.map(({ label, value }) => (
                <li
                  id={value}
                  key={value}
                  role="option"
                  aria-selected={selectedOption === value}
                  tabIndex={0}
                  onKeyDown={handleKeyDown(value)}
                  onClick={() => {
                    if (selectedOption === value) {
                      setSelectedOption();
                      setIsOptionsOpen(false);
                    } else {
                      setSelectedOption(value);
                      setIsOptionsOpen(false);
                    }
                  }}
                >
                  {label}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {displayErrorMessage ? (
          <span role="alert" id={`${name}-error`} className="error-message">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </div>
  );
}

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  height: PropTypes.number,
  wasSubmitted: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  fieldErrorMessage: PropTypes.objectOf(PropTypes.string, PropTypes.string),
  hasCustomFunction: PropTypes.bool,
  clearInput: PropTypes.bool,
  addSelectedObject: PropTypes.func,
};

Select.defaultProps = {
  name: '',
  label: '',
  height: undefined,
  wasSubmitted: undefined,
  fieldErrorMessage: undefined,
  hasCustomFunction: false,
  clearInput: false,
  addSelectedObject: () => {},
};

export default Select;
